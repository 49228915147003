import React            from "react";
// import { Link }         from "gatsby";
import styled           from 'styled-components';
import { Header,
         SubHeader,
         Body }         from '../Typography';
import { Colors } from "../../theme";
import SiteContent      from "../Layouts/SiteContent";
import { NewLink }                     from '../Buttons';

const ContactWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  min-height: 500px;
  height: 30vh;
  background-color: white;
`;

const ContactSection = () => (
	<ContactWrapper id={'Contact'}>
    <SiteContent center>
      <SubHeader medium color={Colors.Black} >Contact</SubHeader>
      <Header color={Colors.Black} h2>Just say ‘Hello.’</Header>
      <Body
        color={Colors.Black}
        maxWidth={680}
        style={{margin: '0 auto', marginBottom: '32px', }}>Busy, eh? Believe me, I know the feeling. But hey, the longer you wait to share your brand, the more business you could be losing out on.<br/> So why wait?
      </Body>
      <NewLink external center noArrow href={`mailto:contact@thumbsupstudio.com`}>Hit us up</NewLink>
    </SiteContent>
	</ContactWrapper>
);

export default ContactSection;