import React          from "react";
import styled         from 'styled-components';
import PropTypes 			from 'prop-types';
import { Colors } 		from '../../theme/Colors';

const propTypes = {
	color  : PropTypes.string,
	thin : PropTypes.bool,
},
defaultProps = {
	color  : Colors.White,
	thin: true,
}

// const SubHead = styled.h5`
// 	font-size: 40px;
// 	letter-spacing: 4px;
// 	color: ${color};
// `;

const SubHeader = ({ children, color, large, medium, margin, thin, fontSize, ...props }) => {

	function transformFontSize ( large, medium ) {
		if (large) {
			return 42;
		} else if (medium) {
			return 32;
		} else {
			return 18;
		} 
	}

	function transformFontSizeMobile ( large, medium ) {
		if (large) {
			return 32;
		} else if (medium) {
			return 26;
		} else {
			return 18;
		} 
	}

	function transformFontWeight ( thin ) {
		if (thin) {
			return 500;
		} else {
			return 700;
		} 
	}

	// 	function transformLetterSpacing ( large ) {
	// 	if (large) {
	// 		return 6;
	// 	} else  {
	// 		return 4;
	// 	}
	// }

	const SubHead = styled.h5`
	  font-family: Rift, sans-serif;
		font-size: ${ ({large, medium, fontSize}) => fontSize ?? transformFontSize(large, medium)}px;
		letter-spacing:${({letterSpacing}) => letterSpacing ?? '1.8px'};
		color: ${color};
		font-weight: ${ ({thin}) => transformFontWeight(thin)};
		padding: 0;
		margin:${({margin}) => margin ? margin : 0};
		text-transform: uppercase;
	  white-space: pre-line;

		::selection {
		  background: #000;
		  color: white;
			background-color: ${Colors.Purple};
		}

	  @media only screen and (max-width: 780px) {
			font-size: ${ ({large, medium, fontSize}) => fontSize ?? transformFontSizeMobile(large, medium)}px;
			letter-spacing:${({letterSpacing}) => letterSpacing ?? '3px'};
	  }
`;

	return (
		<SubHead
			{...props}
			fontSize={fontSize}
			large={large}
			medium={medium}
			thin={thin}
			margin={margin}
			color={color}>
			{children}
		</SubHead>
	);
}

SubHeader.propTypes 	  = propTypes;
SubHeader.defaultProps  = defaultProps;

export default SubHeader;