exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-work-bakusa-js": () => import("./../../../src/pages/work/bakusa.js" /* webpackChunkName: "component---src-pages-work-bakusa-js" */),
  "component---src-pages-work-care-center-stage-js": () => import("./../../../src/pages/work/care-center-stage.js" /* webpackChunkName: "component---src-pages-work-care-center-stage-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-work-launchny-js": () => import("./../../../src/pages/work/launchny.js" /* webpackChunkName: "component---src-pages-work-launchny-js" */)
}

