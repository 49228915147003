import { Link }      from "gatsby";
import styled        from 'styled-components';
import PropTypes     from "prop-types";
import React         from "react";
import { Colors }    from '../theme/Colors';
// import Constants     from '../components/Layout/Constants';
import ButtonPrimary from './Buttons/ButtonPrimary';
import logoDark      from '../images/svgs/thumbsupstudio_logo-dark.svg';
// import instagram     from '../images/svgs/thumbsupstudio_instagram.svg';
// import instagramDark from '../images/svgs/thumbsupstudio_instagram-dark.svg';
// import logo          from '../images/svgs/thumbsupstudio_logo-light.svg';

const PositionWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
`;

const HeaderWrapper = styled.div`
  margin-left: 29px;
  margin-right: 29px;
  display: flex;
  padding-bottom: 1.45rem;
  height: 36px;
  margin-top: 32px;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background-color: transparent;

  @media only screen and (max-width: 780px) {
    flex-direction: column;
    margin-top: 18px;
  }
`;

const LogoWrapper = styled.h1`
  flex-direction: start;
  margin: 0;
  display: block;
  width: 230px;
`;

const MenuWrapper = styled.div`
  display: flex;
  margin-top: -4px;
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 128px;
  background-color: rgba(255, 255, 255, .35);
	backdrop-filter: saturate(210%) blur(6px);
  // min-width: 230px;

  @media only screen and (max-width: 780px) {
  }
`;

const Menu = styled.ul`
  list-style-type: none;
  display: inline;
  margin: 0;
`;

const MenuItem = styled.li`
  display: inline;
  padding-left: 12px;
  padding-right: 12px;
  font-family: Sofia-Pro, sans-serif;
  margin-bottom: 0;
`;

const LinkStyled = styled(Link)`
  border-bottom: 2px solid transparent;
  transition: all 0.6s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
  color: ${Colors.White};
  /* color: ${({ light }) => light ? Colors.White : Colors.Black}; */
  font-size: 18px;
  font-weight: 300;

    &:hover {
      border-bottom: 2px solid transparent;
      color: ${Colors.Purple};
    } 
`;

const Divider = styled.div`
  height: 16px;
  width: 1px;
  background-color: ${Colors.Purple};
  /* margin-left: 16px; */
  margin-right: 12px;
`;

// const InstagramLink = styled.a`
//   width: 14px;
//   height: 14px;
//   display: flex;
// `;

// function renderLogo ( light ) {
//   if (light) {
//     return <img src={logoDark} alt={'Thumbs Up Studio'}/>;
//   } else {
//     return <img src={logo} alt={'Thumbs Up Studio'}/>;
//   }
// }

// function renderInstaLogo ( light ) {
//   if (light) {
//     return <img src={instagramDark} alt={'InstagramLinkDark'}/>;
//   } else {
//     return <img src={instagram} alt={'InstagramLink'}/>;
//   }
// }


const NavBar = ({ siteTitle, light, location }) => (
  <PositionWrapper>
    <HeaderWrapper>
      <LogoWrapper>
        <Link to='/'>
          <img src={logoDark} alt={'Thumbs Up Studio'}/>
          {/* {renderLogo(light)} */}
        </Link>
      </LogoWrapper>
      
      <HeaderRight>
      <MenuWrapper>
        <Menu>
          {/* <MenuItem><LinkStyled light={light} to='/'>Home</LinkStyled></MenuItem> */}
          <MenuItem><LinkStyled to='/work/'>work</LinkStyled></MenuItem>
          <MenuItem><LinkStyled to='/about/'>about</LinkStyled></MenuItem>
          {/* <MenuItem><LinkStyled to='/services/'>services</LinkStyled></MenuItem> */}
        </Menu>
      </MenuWrapper>
          {/* <InstagramLink target="_blank" href={'https://instagram.com/ericwrobinson'}>
            {renderInstaLogo(light)}
          </InstagramLink> */}
        
        <Divider/>
        <ButtonPrimary
          inverted 
          linkTo={'#Contact'}
          title={'contact'}
          color={Colors.Purple}
          textColor={light ? Colors.White : Colors.Purple}
        />
      </HeaderRight>
    </HeaderWrapper>
  </PositionWrapper>
);

NavBar.propTypes = {
  siteTitle: PropTypes.string,
}

NavBar.defaultProps = {
  siteTitle: ``,
}

export default NavBar
