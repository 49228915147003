import React          from "react";
import PropTypes 			from 'prop-types';
import styled         from 'styled-components';
import { Link }       from 'gatsby';
// import { SubHeader }	from '../Typography'
import { Colors } 		from '../../theme/Colors';

import Arrow from '../../images/svgs/arrrow.svg';



const propTypes = {
	color  : PropTypes.string,
	color1 : PropTypes.string,
	color2 : PropTypes.string,
},
defaultProps = {
	color  : Colors.Purple,
	color1  : Colors.Purple,
	color2  : Colors.BabyBlue
}

function transformFontWeight ( thin ) {
	if (thin) {
		return 500;
	} else {
		return 700;
	} 
}

const ArrowWrapper = styled.img`
	width: 21px;
	height: 7px;
	margin-left: 4px;
	transition: all 200ms cubic-bezier(0,.5,0,1);
`;

const Wrapper = styled.div`
	  font-family: Rift, sans-serif;
		font-size: 21px;
		letter-spacing: 3px;
		color: ${({color}) => color};
		font-weight: ${ ({thin}) => transformFontWeight(thin)};
		padding: 0;
		margin: 0 0 12px 0;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: ${({center}) => center ? 'center' : null};
		transition: all 200ms ease;

		::selection {
		  background: #000;
		  color: white;
		}

	  @media only screen and (max-width: 780px) {
			font-size: 21px;
	  	letter-spacing: 3px;
	  }

	  a {
	  	color: ${({color}) => color};
		  position: relative;
		  text-decoration: none;
		}
		img {
			margin-left: 4px;			
			transition: all 200ms ease;
		}
		&:hover {
		letter-spacing: 3.25px;
			img {
				margin-left: 8px;
				transform: scale(1.5);
			}
		}

	  /* a:after {
		  // background-color: ${({color}) => color};
	  	height: 2px;
		  background: linear-gradient(135deg,
		${({ color1 }) => color1} 0%, ${({ color2 }) => color2} 100%);
		  content: "";
		  position: absolute;
	    right: 100%;
	    bottom: .075em;
	    left: 0;
		  transition: right .4s cubic-bezier(0,.5,0,1);
		} */


		/* a:hover:after {
		  right: 0;

		} */
`;

const ExternalLink = styled.a``;



const NewLink = ({ props, to, external, href, children, color, noPadding, color1, color2, center, noArrow }) => {
	console.log('to', to)

	let SwitchLink = external ? ExternalLink : Link;

	return (
		<Wrapper center={center} color={color} color1={color1} color2={color2}>
        <SwitchLink
		{...props}
        href={href}
        external={external} 
        color={color}
        to={to} 
				>{children}</SwitchLink>
				{(!noArrow && to ) &&
					<ArrowWrapper src={Arrow}/>
				}
		</Wrapper>
	);
}

NewLink.propTypes 	  = propTypes;
NewLink.defaultProps  = defaultProps;

export default NewLink;