// import { Link }   from "gatsby"
import styled     from 'styled-components';
import PropTypes  from "prop-types"
import React      from "react"

const FooterWrapper = styled.div`
  margin-bottom: 1.45rem;
  text-align: center;
`;


// const Logo = styled.Link`
//   color: white;
//   text-decoration: none;
// `;

const Footer = ({ siteTitle }) => (
  <FooterWrapper>
    <footer>
      © {new Date().getFullYear()} Thumbs Up Studio LLC
    </footer>
  </FooterWrapper>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer;
